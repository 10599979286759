import React, { lazy } from 'react'
import { Route, Redirect } from 'react-router-dom'

//old route file
import { SealtabsRouter } from '../../../../components/oldRouter'

//components
import { PrivateLayout } from '../../../common/layout/layoutComponent'
const ChangePassword = lazy(() => import('../../../account/changePassword'))

const Dashboard = lazy(() => import('../../../../components/dashboard'))

const TcAndReportCardComponent = lazy(() =>
  import('../../../../components/studentProfile/student-tc-and-report')
)

/* 
    frontend v4 routes 
*/

//studentGroup
const AddStudentGroup = lazy(() =>
  import('../../../studentGroup/addStudentGroup')
)
const RemoveStudentFromStudentGroup = lazy(() =>
  import('../../../studentGroup/removeStudent')
)

//subjects
const AddSubjectsToGroup = lazy(() =>
  import('../../../subjects/addSubject/main')
)
const AddElectivesToExistingSubject = lazy(() =>
  import('../../../subjects/addSubject/elective')
)
const EditSubject = lazy(() => import('../../../subjects/editSubject'))
const AddStudentsToSubject = lazy(() =>
  import('../../../subjects/assignSubject/addStudent')
)
const RemoveStudentsFromSubject = lazy(() =>
  import('../../../subjects/assignSubject/removeStudent')
)
const AddMainSubjectsToGroup = lazy(() =>
  import('../../../subjects/addMainSubject')
)
// tests
const AddTestsToGroup = lazy(() => import('../../../test/addTest/main'))
const EditTestsToGroup = lazy(() => import('../../../test/editTest/main'))
const EditSubTestsToGroup = lazy(() => import('../../../test/editTest/subTest'))
const NewSubTest = lazy(() => import('../../../test/addTest/subTest'))
const AddSubtest = lazy(() => import('../../../test/addTest/addSubTest'))

//teacher
const AddEditTeacher = lazy(() => import('../../../teacher/addEditTeacher'))
// const BulkTeacherUserAdd = lazy(() =>
//   import('../../../teacher/bulkCraeteTeacherUser')
// )
const BulkTeacherUserAdd = lazy(() => import('../../../teacher/bulkCreation'))
const ClassTeacherList = lazy(() => import('../../../teacher/classTeacherList'))
const TeacherList = lazy(() => import('../../../teacher/teacherList'))
const TeacherProfile = lazy(() => import('../../../teacher/teacherProfile'))
const TeacherDocuments = lazy(() => import('../../../teacher/teacherDocument'))
const BulkTeacherUpload = lazy(() => import('../../../teacher/bulkUpload'))
const TeacherCertificates = lazy(() =>
  import('../../../teacher/teacherCertificates')
)

//institution
const AddEditInstitution = lazy(() =>
  import('../../../institution/addEditInstitution')
)
const EditFinicialInstitution = lazy(() =>
  import('../../../institution/editFinicialInstitution')
)
const EditInstitutionInvoice = lazy(() =>
  import('../../../institution/editInvoiceDetails')
)
const AllInstitution = lazy(() =>
  import('../../../institution/allInstitutions')
)

const FinanceInstitution = lazy(() =>
  import('../../../institution/financeInstitutions')
)
const AllInstitutionUsers = lazy(() => import('../../../institution/viewUsers'))
const InstitutionDetails = lazy(() =>
  import('../../../studentGroup/classOverview')
)
const InstitutionDetailsPerYear = lazy(() =>
  import('../../../studentGroup/classOverviewByYear')
)
const FeeReceiptNumber = lazy(() =>
  import('../../../institution/feeReceiptNumber')
)

//class profile
const ClassProfile = lazy(() => import('../../../studentGroup/classProfile'))

//hour
const AddEditHour = lazy(() => import('../../../hour/addEditHour'))
const hourAddEdit = lazy(() => import('../../../hour/hourAddEdit'))

//student
const AddEditStudent = lazy(() => import('../../../student/addEditStudent'))
const BulkStudentUserAdd = lazy(() =>
  import('../../../student/bulkCreateStudentUser')
)
const StudentSearch = lazy(() => import('../../../student/search'))
const BulkStudentUpload = lazy(() =>
  import('../../../student/bulkStudentUpload')
)
const StudentList = lazy(() => import('../../../student/studentList'))
const StudentProfile = lazy(() => import('../../../student/studentProfile'))
const StudentDocuments = lazy(() =>
  import('../../../student/studentCertificates')
)
const StudentTCReport = lazy(() => import('../../../student/studentTC'))
const StudentPerformance = lazy(() =>
  import('../../../student/studentPerformance')
)
const StudentEmail = lazy(() => import('../../../student/studentEmails'))
const StudentSms = lazy(() => import('../../../student/studentSms'))
const StudentListByGroupType = lazy(() =>
  import('../../../student/studentListByGroupType')
)
const StudentPictureUpload = lazy(() =>
  import('../../../student/studentPicList')
)

//class edit
const EditStudentGroup = lazy(() =>
  import('../../../studentGroup/editStudentGroup')
)

//grades
const AddGrades = lazy(() => import('../../../grades/addGrades'))
const EditGrades = lazy(() => import('../../../grades/editGrades'))

//marks
const MarksEntry = lazy(() => import('../../../marks/marksEntry'))
const MarksReportLevel2 = lazy(() =>
  import('../../../marks/marksReport/level2')
)
const MarksReportLevel3 = lazy(() =>
  import('../../../marks/marksReport/level3')
)
const SubtestMarksReport = lazy(() =>
  import('../../../marks/marksReport/subTest')
)
const MarksReportBCGS = lazy(() =>
  import('../../../marks/marksReport/cumulative/bcgs/marksReport')
)
const CumulativeReportBCGS = lazy(() =>
  import('../../../marks/marksReport/cumulative/bcgs/cumulative')
)
const CumulativeReportSJHS = lazy(() =>
  import('../../../marks/marksReport/cumulative/sjhs')
)
const CumulativeAverage = lazy(() =>
  import('../../../marks/marksReport/cumulative/average/termWise')
)
const CumulativeAverageAllClass = lazy(() =>
  import('../../../marks/marksReport/cumulative/average/allClass')
)
const ConsolidatedReport = lazy(() =>
  import('../../../marks/marksReport/cumulative/consolidated')
)
const MarksReportSubjectWise = lazy(() =>
  import('../../../marks/marksReport/subjectWise')
)
const TestWiseAnalysis = lazy(() => import('../../../marks/analysis/testWise'))
const GraphicalAnalysis = lazy(() =>
  import('../../../marks/analysis/graphical')
)
const TabularAnalysis = lazy(() => import('../../../marks/analysis/tabular'))
const TabularAnalysisNew = lazy(() =>
  import('../../../marks/analysis/tabularNew')
)
const SslcConsolidatedReport = lazy(() =>
  import('../../../marks/marksReport/cumulative/consolidatedSslc')
)
const RBANMSConsolidated = lazy(() =>
  import('../../../marks/marksReport/customRbanms')
)
const ConsolidatedVis = lazy(() =>
  import('../../../marks/marksReport/consolidatedVis')
)
const ConsolidatedCpsd = lazy(() =>
  import('../../../marks/marksReport/consolidatedCpsd')
)
const SubtestConsolidate = lazy(() =>
  import('../../../marks/marksReport/subtestConsolidate')
)
const ConsolidateSJHSNineAndEleven = lazy(() =>
  import('../../../marks/marksReport/consolidatedSjhs/nineAndEleven')
)
const PeriodicTestReport = lazy(() =>
  import('../../../marks/marksReport/periodicTestReport')
)

//marks card
const MarksCardGenerate = lazy(() =>
  import('../../../marks/marksCard/generate')
)
const MarksCardStatus = lazy(() => import('../../../marks/marksCard/status'))
const PublishReportCard = lazy(() => import('../../../marks/publish'))

//marks remarks
const StudentMarksRemarks = lazy(() =>
  import('../../../remarks/studentRemarks')
)

//rank
const GenerateRank = lazy(() => import('../../../marks/rank'))

//Leave policies
const CreatePolicy = lazy(() =>
  import('../../../leaveManagement/policy/createPolicy')
)

const EditPolicy = lazy(() =>
  import('../../../leaveManagement/policy/editPolicy')
)

const AssignLeavePolicy = lazy(() =>
  import('../../../leaveManagement/policy/assignPolicy')
)

const LeavePolicy = lazy(() => import('../../../leaveManagement/policy'))
const HolidayPolicy = lazy(() =>
  import('../../../leaveManagement/holidayPolicy')
)
const LeaveApproval = lazy(() =>
  import('../../../leaveManagement/leaveApproval')
)

//teacher Attendance
const TeacherAttendance = lazy(() => import('../../../attendance/teacher/main'))

//teacher leave application
const TeacherLeaveApplication = lazy(() =>
  import('../../../leaveManagement/leaveApplication/teacher')
)
const TeacherLeaveApplicationList = lazy(() =>
  import('../../../leaveManagement/leaveApplication/teacher/leaveApplications')
)
const TeacherLeaveBalance = lazy(() =>
  import('../../../leaveManagement/leaveApplication/teacher/leaveBalance')
)
const LeaveApprovalTeacher = lazy(() =>
  import('../../../leaveManagement/leaveApproval/approvalTeacherProfile')
)
const LeavePolicyTeacher = lazy(() =>
  import('../../../leaveManagement/policy/policyTeacherProfile')
)

//Payslip
const GeneratePayslip = lazy(() =>
  import('../../../payrollManagement/generatePayslip')
)
const DownloadPayslip = lazy(() =>
  import('../../../payrollManagement/downloadPayslip')
)
const GeneratePayslipTeacher = lazy(() =>
  import('../../../payrollManagement/generatePayslip/generateInTeacherProfile')
)
const DownloadPayslipTeacher = lazy(() =>
  import(
    '../../../payrollManagement/downloadPayslip/downloadPayslipTeacherProfile'
  )
)

//payroll policy
const PayrollPolicy = lazy(() => import('../../../payrollManagement/policy'))
const AddEditPayrollPolicy = lazy(() =>
  import('../../../payrollManagement/policy/addEditPolicy')
)
const AllocatePolicy = lazy(() =>
  import('../../../payrollManagement/policy/assignPolicy')
)

//payslips
const PayslipDownload = lazy(() =>
  import('../../../payrollManagement/downloadPayslip/teacher')
)
const FinancialDetailsTeacher = lazy(() =>
  import('../../../payrollManagement/financialDetails')
)
const FinancialDetailsBulk = lazy(() =>
  import('../../../payrollManagement/financialDetails/financialDetailsBulk')
)
const PayslipReport = lazy(() =>
  import('../../../payrollManagement/payslipReport')
)

//fees
const FeeDueReminder = lazy(() =>
  import('../../../fees/reports/feeDueReminder')
)
const FeeStructure = lazy(() => import('../../../fees/feeStructure'))
const FeeStructureList = lazy(() => import('../../../fees/feeStructureList'))
const AssignFees = lazy(() => import('../../../fees/assignFees'))
const StudentFee = lazy(() => import('../../../fees/studentFee'))
const ViewReceipt = lazy(() => import('../../../fees/studentFee/viewReceipt'))
const FeeCollection = lazy(() =>
  import('../../../fees/reports/collectionReport')
)
const FeeRegister = lazy(() => import('../../../fees/reports/feeRegister'))
const FeeStatusReport = lazy(() => import('../../../fees/reports/statusReport'))
const FeeAnalytics = lazy(() => import('../../../fees/reports/feeAnalytics'))
const FeeConcessionReport = lazy(() =>
  import('../../../fees/reports/concessionReport')
)
const FeeArrearReport = lazy(() =>
  import('../../../fees/reports/arrearsReport')
)
const DeleteStudentFees = lazy(() => import('../../../fees/deleteFees'))
const FeeWaiveReport = lazy(() => import('../../../fees/reports/waiveReport'))
const FeeGroupBifurcation = lazy(() =>
  import('../../../fees/reports/feeGroupBifurcation')
)
const StudentFeeDetails = lazy(() =>
  import('../../../fees/reports/studentDetails')
)
const BulkFeeReceipt = lazy(() => import('../../../fees/bulkFeeReceipt'))
const ConcessionFeeApproval = lazy(() =>
  import('../../../fees/concessionApproval')
)
const DeletedFees = lazy(() => import('../../../fees/deletedFees'))

//transport fee structure
const TransportFeeStructure = lazy(() =>
  import('../../../fees/transportFeeStructure')
)
const TransportFeeStructureList = lazy(() =>
  import('../../../fees/transportFeeStructureList')
)

//fine template
const ListFineTemplate = lazy(() => import('../../../fees/fineTemplate'))

//tally fee reports
const ConsolidatedFeeReport = lazy(() =>
  import('../../../fees/tallyReports/consolidatedFee')
)
const DeferredFee = lazy(() => import('../../../fees/tallyReports/deferredFee'))
const DeletedReceipts = lazy(() =>
  import('../../../fees/tallyReports/deletedReceipts')
)

//marks
const MarksBulkEntry = lazy(() => import('../../../marks/marksBulk'))

//sms templates
const AddSmsTemplate = lazy(() => import('../../../smsTemplate/addTemplate'))
const ViewAllTemplate = lazy(() =>
  import('../../../smsTemplate/viewAllTemplate')
)

//sms
const SmsApprove = lazy(() => import('../../../sms/smsApprove'))
const SmsInfo = lazy(() => import('../../../sms/smsInfo'))
const CustomSms = lazy(() => import('../../../sms/customSms'))
const BulkSms = lazy(() => import('../../../sms/bulkSms'))

//hallticket
const ExamTimetable = lazy(() => import('../../../exam/timetable'))
const GenerateHallticket = lazy(() =>
  import('../../../exam/hallticket/generateHallticket')
)
const DownloadHallticket = lazy(() =>
  import('../../../exam/hallticket/downloadHallticket')
)

//feedback
//survey reports
const SurveyReports = lazy(() =>
  import('../../../feedbackAndSurveys/surveyReports')
)
const FeedbackReport = lazy(() =>
  import('../../../feedbackAndSurveys/feedbackReport')
)
const AddFeedback = lazy(() =>
  import('../../../feedbackAndSurveys/addFeedback')
)
const ListFeedback = lazy(() =>
  import('../../../feedbackAndSurveys/listFeedback')
)
const FeedbackQuestions = lazy(() =>
  import('../../../feedbackAndSurveys/feedbackQuestions')
)

//student attendance report
const AttendanceReportByGender = lazy(() =>
  import('../../../attendance/student/attendanceReport/genderBased')
)

//question bank
const QuestionBankUpload = lazy(() => import('../../../questionBank/upload'))
const QuestionBankList = lazy(() => import('../../../questionBank/list'))
const ViewQuestionBank = lazy(() => import('../../../questionBank/view'))
const QuestionBankBulkUpload = lazy(() => import('../../../questionBank/bulk'))

//Worksheet
const WorksheetList = lazy(() => import('../../../worksheet/viewAll'))
const WorksheetListSingle = lazy(() => import('../../../worksheet/viewSingle'))
const AddWorksheet = lazy(() => import('../../../worksheet/addWorksheet'))
//homework
const AddEditHomework = lazy(() => import('../../../homework/addEditHomework'))
const HomeworkList = lazy(() => import('../../../homework/homeworkList'))
const AllHomeworkList = lazy(() => import('../../../homework/allHomeworkList'))

//Exam porion and timetable
const AllExamPortionsTimetable = lazy(() =>
  import('../../../examPortionTimetable/viewPortion')
)
const AddExamPortionsTimetable = lazy(() =>
  import('../../../examPortionTimetable/addPortion/addPortion')
)
const StudentExamPortionTimetableView = lazy(() =>
  import('../../../examPortionTimetable/view')
)

//email
const EmailView = lazy(() => import('../../../email/emailView'))
const EmailInfo = lazy(() => import('../../../email/emailInfo'))
const BulkEmail = lazy(() => import('../../../email/bulkEmail'))
const CustomEmail = lazy(() => import('../../../email/customEmail'))

//admission
const NewEnquiry = lazy(() => import('../../../admission/enquiry'))
const EnquiryList = lazy(() => import('../../../admission/enquiryList'))
const EnquiryListNew = lazy(() => import('../../../admission/enquiryListNew'))
const ApplicantProfile = lazy(() =>
  import('../../../admission/applicantProfile')
)

//attendance
const AttendanceNew = lazy(() => import('../../../attendance/attendanceNew'))
const AttendanceEntry = lazy(() =>
  import('../../../attendance/attendanceEntry')
)
const AttendanceHalfDay = lazy(() =>
  import('../../../attendance/attendanceHalfDay')
)
const AttendanceRemarksReport = lazy(() =>
  import('../../../attendance/attendanceRemarksReport')
)
const AttendanceReport = lazy(() =>
  import('../../../attendance/attendanceReports/mainReport')
)
const CpdcAttendanceReport = lazy(() =>
  import('../../../attendance/attendanceReports/cpdcAttdReport')
)
const TeacherAttendanceReport = lazy(() =>
  import('../../../attendance/attendanceReports/teacherReport')
)
const BulkEntry = lazy(() => import('../../../attendance/bulkEntry'))
const HourlyCompletionReport = lazy(() =>
  import('../../../attendance/attendanceReports/hourlyCompletionReport')
)
const WeeklyHourlyAttendanceReport = lazy(() =>
  import('../../../attendance/attendanceReports/weeklyReport')
)
const AttendanceCorrection = lazy(() =>
  import('../../../attendance/attendanceCorrection')
)
const SubjectWiseAttendanceReport = lazy(() =>
  import('../../../attendance/attendanceReports/subjectWiseReport')
)
const AttendanceDelete = lazy(() =>
  import('../../../attendance/attendanceDelete/classwise')
)
const DailyAttendanceDeletePerStudent = lazy(() =>
  import('../../../attendance/attendanceDelete/perStudent')
)
const VijayaAttendanceReportSubjectwise = lazy(() =>
  import('../../../attendance/attendanceReports/vijayaAttdReport')
)

//timetable
const Timetable = lazy(() => import('../../../timetable/updateTimetable'))

//certificates
const TransferCertificate = lazy(() =>
  import('../../../certificates/transferCertificate')
)
const RegisterCertificate = lazy(() =>
  import('../../../certificates/registerCertificate')
)
const ConductCertificate = lazy(() =>
  import('../../../certificates/conductCertificate')
)
const CertificateStatus = lazy(() => import('../../../certificates/status'))

//id cards
const IdCardGenerate = lazy(() => import('../../../idCard/generate'))
const IdCardStatus = lazy(() => import('../../../idCard/status'))

//events
const AddEvent = lazy(() => import('../../../events/addEvent'))
const EventList = lazy(() => import('../../../events/editEvent'))

//CMS
const CmsList = lazy(() => import('../../../cms/listCms'))
const AddCMS = lazy(() => import('../../../cms/addCms'))
const CustomCms = lazy(() => import('../../../cms/customCms'))

//student publish report card
const StudentPublishedReportCard = lazy(() =>
  import('../../../student/publishedReportCard')
)

//gellery
const UploadGallery = lazy(() => import('../../../gallery/addgallery'))
const ViewGallery = lazy(() => import('../../../gallery/viewGallery'))
const ViewAlbum = lazy(() => import('../../../gallery/viewSingleGallery'))

//student list
const BoardersList = lazy(() => import('../../../studentList/boarders'))
const StudentSubjectlist = lazy(() => import('../../../studentList/subjects'))
const StudentCount = lazy(() => import('../../../studentList/count'))

//list of books
const LibrarySummary = lazy(() => import('../../../library/summary/index'))
const BulkCatalogUpload = lazy(() =>
  import('../../../library/catalog/bulkCatalogUpload')
)

//biometric
const AddBiometricDevice = lazy(() => import('../../../biometric/addDevice'))
const AssignTeacherToFingerPrint = lazy(() =>
  import('../../../biometric/assignTeacher')
)

const ArchivedStudents = lazy(() =>
  import('../../../studentList/archivedStudents')
)

//forgotpassword
const ForgotPasswordComponent = lazy(() =>
  import('../../../account/forgotPassword')
)

//student-remarks
const AddStudentRemarks = lazy(() => import('../../../remarks/addRemarks'))
const ListRemarks = lazy(() => import('../../../remarks/listRemarks'))

//promotion
const DuplicateClassForNextYear = lazy(() =>
  import('../../../promotion/duplicateClass')
)
const DuplicateTest = lazy(() => import('../../../promotion/duplicateTest'))
const PromoteStudents = lazy(() => import('../../../promotion/promoteStudents'))

//sibling login
const CreateSiblingLogin = lazy(() => import('../../../siblingLogin/create'))

//student document
const StudentDocumentAlert = lazy(() =>
  import('../../../studentDocument/documentAlert')
)
const StudentDocumentAlertList = lazy(() =>
  import('../../../studentDocument/doucmentAlertList')
)
const DocumentListByType = lazy(() =>
  import('../../../studentDocument/documentList')
)
const StudentUploadDocument = lazy(() =>
  import('../../../student/studentUploadDocument')
)

//fee_types
const FeeTypeList = lazy(() => import('../../../fees/feeTypes'))

//challan
const ChallanList = lazy(() => import('../../../fees/challan'))

//lesson plan
const LessonPlanAddEdit = lazy(() =>
  import('../../../lessonPlan/addEditLessonPlan/index')
)
const LessonPlanList = lazy(() => import('../../../lessonPlan/list/index'))
const LessonPlanVerification = lazy(() =>
  import('../../../lessonPlan/verifyByHOD')
)
const LessonPlanApproval = lazy(() =>
  import('../../../lessonPlan/approvalByPrincipal')
)

//student certificate
const StudentCertificateAdd = lazy(() =>
  import(
    '../../../studentTeacherCertificate/addStudentTeacherCertificate/index'
  )
)
const StudentCertificateList = lazy(() =>
  import(
    '../../../studentTeacherCertificate/studentTeacherCertificateList/index'
  )
)

//notes
const AddNotes = lazy(() => import('../../../notes/addNotes'))
const ListNotes = lazy(() => import('../../../notes/listNotes'))

//sibling parent list
const ParentList = lazy(() => import('../../../siblingLogin/parentList'))

//MOM
const MomentsOfMeeting = lazy(() => import('../../../mom/momAdd'))
const MomentsOfMeetingView = lazy(() => import('../../../mom/momView'))
const MomentsOfMeetingList = lazy(() => import('../../../mom/momList'))

//MIT CO-PO report
const COAttainment = lazy(() =>
  import('../../../marks/marksReport/mitReports/coAttainment')
)

//Query ticketing
const QueryListTable = lazy(() => import('../../../queryTicket/queryList'))
const QueryChat = lazy(() => import('../../../queryTicket/queryChat'))
const queryTicketReport = lazy(() => import('../../../queryTicket/queryReport'))

//tranportVehicle
const AddVehicle = lazy(() =>
  import('../../../transportation/vehicle/addvehicle')
)
const ListVehicle = lazy(() =>
  import('../../../transportation/vehicle/listVehicle')
)

//driver
const AddEditDriver = lazy(() =>
  import('../../../transportation/driver/addEditDriver')
)
const ListDriver = lazy(() =>
  import('../../../transportation/driver/listDriver')
)

//transport route and stop
const AddRoute = lazy(() => import('../../../transportation/route/addRoute'))
const ListRoute = lazy(() => import('../../../transportation/route/listRoute'))
const AddEditStop = lazy(() =>
  import('../../../transportation/stop/addEditStop')
)
const StopList = lazy(() => import('../../../transportation/stop/listStop'))
const AssignMembers = lazy(() => import('../../../transportation/assign'))
const UnassignMembers = lazy(() => import('../../../transportation/unassign'))

//lesson plan chapters
const AddChapters = lazy(() => import('../../../lessonPlan/addChapters'))
const ListChapters = lazy(() => import('../../../lessonPlan/listChapters'))

const NotFound = () => (
  <div>
    <h1>404 - Not Found!</h1>
  </div>
)

const CommonRoute = () => {
  return (
    <>
      <SealtabsRouter />
      <Route
        exact
        path="/"
        name="home"
        render={() => <Redirect to="/dashboard" />}
      />
      <Route
        exact
        path="/login"
        name="home"
        render={() => <Redirect to="/dashboard" />}
      />

      {/* frontend v3 routes */}

      <PrivateLayout
        exact
        path="/dashboard"
        name="dashboard"
        component={Dashboard}
      />
      <PrivateLayout
        exact
        path="/change-password"
        name="changePassword"
        component={ChangePassword}
      />
      <PrivateLayout
        exact
        path="/student/tc-reports/:id"
        name="edit-student"
        component={TcAndReportCardComponent}
      />
      {/* frontend v4 routes */}

      <PrivateLayout
        exact
        path="/studentgroup/classes"
        name="groups"
        component={InstitutionDetails}
      />
      <PrivateLayout
        exact
        path="/studentgroup/year"
        name="groups by year"
        component={InstitutionDetailsPerYear}
      />
      <PrivateLayout
        exact
        path="/studentgroup/class/profile"
        name="class profile"
        component={ClassProfile}
      />
      <PrivateLayout
        exact
        path="/studentgroup/class/add"
        name="group-new"
        component={AddStudentGroup}
      />
      <PrivateLayout
        exact
        path="/student/add"
        name="group-add-student"
        component={AddEditStudent}
      />
      <PrivateLayout
        exact
        path="/student/edit/:id"
        name="edit-student"
        component={AddEditStudent}
      />
      <PrivateLayout
        exact
        path="/studentgroup/subject/add"
        name="group-add-subjects"
        component={AddSubjectsToGroup}
      />
      <PrivateLayout
        exact
        path="/studentgroup/subject/add-main"
        name="group-add-subjects"
        component={AddMainSubjectsToGroup}
      />
      <PrivateLayout
        exact
        path="/studentgroup/elective/add"
        name="group-add-subjects"
        component={AddElectivesToExistingSubject}
      />
      <PrivateLayout
        exact
        path="/studentgroup/test/add"
        name="group-add-tests"
        component={AddTestsToGroup}
      />
      <PrivateLayout
        exact
        path="/studentgroup/test/edit"
        name="group-add-tests"
        component={EditTestsToGroup}
      />
      <PrivateLayout
        exact
        path="/studentgroup/subtest/edit"
        name="group-add-tests"
        component={EditSubTestsToGroup}
      />
      <PrivateLayout
        exact
        path="/studentgroup/subtest/add"
        name="group-add-tests"
        component={NewSubTest}
      />
      <PrivateLayout
        exact
        path="/studentgroup/subtest/add-subtest"
        name="group-add-tests"
        component={AddSubtest}
      />
      <PrivateLayout
        exact
        path="/studentgroup/subject/edit"
        name="group-edit-subject"
        component={EditSubject}
      />
      <PrivateLayout
        exact
        path="/studentgroup/subjects/add-students"
        name="addStudentsToElectives"
        component={AddStudentsToSubject}
      />
      <PrivateLayout
        exact
        path="/studentgroup/subjects/remove-students"
        name="removeStudentsFromElectives"
        component={RemoveStudentsFromSubject}
      />
      <PrivateLayout
        exact
        path="/studentgroup/hour/:type"
        name="group-add-hour"
        component={AddEditHour}
      />
      <PrivateLayout
        exact
        path="/studentGroup/hour"
        name="group-add-edit-hour"
        component={hourAddEdit}
      />
      <PrivateLayout
        exact
        path="/studentgroup/class/edit"
        name="edit studentgroup"
        component={EditStudentGroup}
      />
      <PrivateLayout
        exact
        path="/studentgroup/grades/add"
        name="grades"
        component={AddGrades}
      />
      <PrivateLayout
        exact
        path="/studentgroup/grades/edit"
        name="grades"
        component={EditGrades}
      />
      <PrivateLayout
        exact
        path="/studentgroup/students/remove"
        name="remove students from studentgroup"
        component={RemoveStudentFromStudentGroup}
      />
      <PrivateLayout
        exact
        path="/institution/add"
        name="institution"
        component={AddEditInstitution}
      />
      <PrivateLayout
        exact
        path="/institution/edit/:id"
        name="institution"
        component={AddEditInstitution}
      />
      <PrivateLayout
        exact
        path="/institution/fee-receipt-number/:id"
        name="institution"
        component={FeeReceiptNumber}
      />
      <PrivateLayout
        exact
        path="/institution/edit/finance/:id"
        name="institution"
        component={EditFinicialInstitution}
      />
      <PrivateLayout
        exact
        path="/institution/edit/invoice/:id"
        name="institution"
        component={EditInstitutionInvoice}
      />
      <PrivateLayout
        exact
        path="/institution/all"
        name="institution"
        component={AllInstitution}
      />
      <PrivateLayout
        exact
        path="/institution/finance"
        name="institution"
        component={FinanceInstitution}
      />
      <PrivateLayout
        exact
        path="/institution/view/users"
        name="institution"
        component={AllInstitutionUsers}
      />
      <PrivateLayout
        exact
        path="/marks/entry"
        name="marks entry"
        component={MarksEntry}
      />
      <PrivateLayout
        exact
        path="/marks/report/level-2"
        name="marks report level2"
        component={MarksReportLevel2}
      />
      <PrivateLayout
        exact
        path="/marks/report/level-3"
        name="marks report level3"
        component={MarksReportLevel3}
      />
      <PrivateLayout
        exact
        path="/marks/report/sub-test"
        name="marks report subtest"
        component={SubtestMarksReport}
      />
      <PrivateLayout
        exact
        path="/marks/marks-report"
        name="bcgs marks report"
        component={MarksReportBCGS}
      />
      <PrivateLayout
        exact
        path="/marks/report/cumulative"
        name="bcgs marks report cumulative"
        component={CumulativeReportBCGS}
      />
      <PrivateLayout
        exact
        path="/marks/report/assessment-cumulative"
        name="sjhs marks report cumulative"
        component={CumulativeReportSJHS}
      />
      <PrivateLayout
        exact
        path="/marks/report/sslc-cumulative"
        name="sslc marks report cumulative"
        component={SslcConsolidatedReport}
      />
      <PrivateLayout
        exact
        path="/marks/report/avg-cumulative"
        name="all marks report cumulative"
        component={CumulativeAverage}
      />
      <PrivateLayout
        exact
        path="/marks/report/avg-cumulative/all"
        name="all marks report cumulative"
        component={CumulativeAverageAllClass}
      />
      <PrivateLayout
        exact
        path="/marks-report/consolidated"
        name="consolidated marks report"
        component={ConsolidatedReport}
      />
      <PrivateLayout
        exact
        path="/marks/report/subject-wise"
        name="marks-report"
        component={MarksReportSubjectWise}
      />
      <PrivateLayout
        exact
        path="/marks/report/custom-consolidated"
        name="marks-report"
        component={ConsolidatedVis}
      />
      <PrivateLayout
        exact
        path="/marks/report/custom-consolidated-termwise"
        name="marks-report"
        component={ConsolidatedCpsd}
      />
      <PrivateLayout
        exact
        path="/marks/report/subtest-consolidate"
        name="marks-report"
        component={SubtestConsolidate}
      />
      <PrivateLayout
        exact
        path="/marks/report/consolidate/test-percentage"
        name="marks-report sjhs 9 & 11"
        component={ConsolidateSJHSNineAndEleven}
      />
      <PrivateLayout
        path="/generate-payslip"
        name="Generate Payslip"
        component={GeneratePayslip}
      />
      <PrivateLayout
        path="/download-payslip"
        name="Download payslip"
        component={DownloadPayslip}
      />
      <PrivateLayout
        path="/teacher/generate-payslip/:id"
        name="Generate Payslip"
        component={GeneratePayslipTeacher}
      />
      <PrivateLayout
        path="/teacher/download-payslip/:id"
        name="Download Payslip"
        component={DownloadPayslipTeacher}
      />
      <PrivateLayout
        path="/teacher/leave-policy/:id"
        name="Assign policy"
        component={LeavePolicyTeacher}
      />
      <PrivateLayout
        path="/teacher/leave-details/:id"
        name="Leave Details"
        component={LeaveApprovalTeacher}
      />
      <PrivateLayout
        path="/teacher/financial-details/:id"
        name="Financial Details"
        component={FinancialDetailsTeacher}
      />
      <PrivateLayout
        path="/financial-details"
        name="Financial Details"
        component={FinancialDetailsBulk}
      />
      <PrivateLayout
        exact
        path="/leave-management/create-policy"
        name="createPolicy"
        component={CreatePolicy}
      />
      <PrivateLayout
        exact
        path="/leave-management/holiday-policy"
        name="holidayPolicy"
        component={HolidayPolicy}
      />
      <PrivateLayout
        exact
        path="/leave-management/leave-policy"
        name="leavePolicy"
        component={LeavePolicy}
      />
      <PrivateLayout
        exact
        path="/leave-management/leave-policy/edit/:policy"
        name="editPolicy"
        component={EditPolicy}
      />
      <PrivateLayout
        exact
        path="/leave-management/leave-policy/assign"
        name="editPolicy"
        component={AssignLeavePolicy}
      />
      <PrivateLayout
        exact
        path="/leaveManagement/leaveApproval"
        name="leaveApproval"
        component={LeaveApproval}
      />
      <PrivateLayout
        path="/attendance/teacher"
        name="teacher attendance"
        component={TeacherAttendance}
      />
      <PrivateLayout
        path="/me/apply-leave/:type"
        name="teacher apply-leave"
        component={TeacherLeaveApplication}
      />
      <PrivateLayout
        path="/me/leaves"
        name="teacher pending leaves"
        component={TeacherLeaveApplicationList}
      />
      <PrivateLayout
        path="/me/leave-balance"
        name="teacher leave balance"
        component={TeacherLeaveBalance}
      />
      <PrivateLayout
        path="/payroll-policy"
        name="payroll policy"
        component={PayrollPolicy}
      />
      <PrivateLayout
        path="/payroll/policy/:type"
        name="create/edit"
        component={AddEditPayrollPolicy}
      />
      <PrivateLayout
        path="/teacher/assign/payroll/:id"
        name="assign payroll policy"
        component={AllocatePolicy}
      />
      <PrivateLayout
        path="/payslip/download"
        name="download payslip"
        component={PayslipDownload}
      />
      <PrivateLayout
        path="/payslip/report"
        name="payslip report"
        component={PayslipReport}
      />
      <PrivateLayout
        path="/fee-due/reminder"
        name="fee due reminder"
        component={FeeDueReminder}
      />
      <PrivateLayout
        path="/fee/fee-due/reminder"
        name="fee due reminder"
        component={FeeDueReminder}
      />
      <PrivateLayout
        path="/marks/bulk/upload"
        name="marks bulk upload csv"
        component={MarksBulkEntry}
      />
      <PrivateLayout
        path="/sms-template/add"
        name="add sms templates"
        component={AddSmsTemplate}
      />
      <PrivateLayout
        path="/sms-template/edit/:id"
        name="edit sms templates"
        component={AddSmsTemplate}
      />
      <PrivateLayout
        path="/sms-template/view/all"
        name="view all sms templates"
        component={ViewAllTemplate}
      />
      <PrivateLayout
        path="/exam/timetable"
        name="Exam Timetable"
        component={ExamTimetable}
      />
      <PrivateLayout
        path="/exam/hallticket/generate"
        name="Generate Hallticket"
        component={GenerateHallticket}
      />
      <PrivateLayout
        path="/exam/hallticket/download"
        name="Download Hallticket"
        component={DownloadHallticket}
      />
      {/* <PrivateLayout
        path="/feedback/survey/report"
        name="Survey Report"
        component={SurveyReports}
      /> */}
      <PrivateLayout
        path="/feedback/survey/report"
        name="Survey Report"
        component={FeedbackReport}
      />
      <PrivateLayout
        path="/feedback/add"
        name="Add Feedback"
        component={AddFeedback}
      />
      <PrivateLayout
        path="/feedback/list"
        name="List Feedback"
        component={ListFeedback}
      />
      <PrivateLayout
        path="/feedback/view/:id"
        name="List Feedback"
        component={FeedbackQuestions}
      />
      <PrivateLayout
        path="/attendance/all-students/report"
        name="Students Attendance Report by gender"
        component={AttendanceReportByGender}
      />
      <PrivateLayout
        exact
        path="/question-bank/:type"
        name="upload question bank"
        component={QuestionBankUpload}
      />
      <PrivateLayout
        exact
        path="/question-bank/view/all"
        name="list question bank"
        component={QuestionBankList}
      />
      <PrivateLayout
        exact
        path="/question-bank/view/paper/:id"
        name="view question bank"
        component={ViewQuestionBank}
      />
      <PrivateLayout
        exact
        path="/question-bank/bulk/:type"
        name="view question bank"
        component={QuestionBankBulkUpload}
      />
      <PrivateLayout
        exact
        path="/worksheet/add"
        name="add worksheet"
        component={AddWorksheet}
      />
      <PrivateLayout
        exact
        path="/worksheet/edit/:id"
        name="edit worksheet"
        component={AddWorksheet}
      />
      <PrivateLayout
        exact
        path="/worksheet/view/all"
        name="view worksheet"
        component={WorksheetList}
      />
      <PrivateLayout
        exact
        path="/worksheet/view/paper/:id"
        name="worksheet single"
        component={WorksheetListSingle}
      />
      <PrivateLayout
        exact
        path="/homework-assignment/add"
        name="add homework/assignment"
        component={AddEditHomework}
      />
      <PrivateLayout
        exact
        path="/homework-assignment/edit/:id"
        name="add homework/assignment"
        component={AddEditHomework}
      />
      <PrivateLayout
        exact
        path="/homework-assignment/list"
        name="homework single"
        component={AllHomeworkList}
      />
      {/* <PrivateLayout
        exact
        path="/homework-assignment/all"
        name="homework single"
        component={HomeworkList}
      /> */}
      <PrivateLayout
        exact
        path="/exam-portion-timetable/all"
        name="exam-portion-timetable"
        component={AllExamPortionsTimetable}
      />
      <PrivateLayout
        exact
        path="/exam-portion-timetable/add"
        name="exam-portion-timetable"
        component={AddExamPortionsTimetable}
      />
      <PrivateLayout
        exact
        path="/exam-portion-timetable"
        name="exam-portion-timetable"
        component={StudentExamPortionTimetableView}
      />
      <PrivateLayout
        exact
        path="/sms/approve"
        name="approve/reject sms"
        component={SmsApprove}
      />
      <PrivateLayout
        exact
        path="/sms/info/:id"
        name="sms info"
        component={SmsInfo}
      />
      <PrivateLayout
        exact
        path="/sms/custom/:recepient"
        name="custom sms"
        component={CustomSms}
      />
      <PrivateLayout
        exact
        path="/sms/bulk"
        name="bulk sms"
        component={BulkSms}
      />
      <PrivateLayout
        exact
        path="/user/teacher/bulk/add"
        name="add-bulk-teacher-user"
        component={BulkTeacherUserAdd}
      />
      <PrivateLayout
        exact
        path="/teacher/classteacher"
        name="add-bulk-teacher-user"
        component={ClassTeacherList}
      />
      <PrivateLayout
        exact
        path="/email/view"
        name="view emails"
        component={EmailView}
      />
      <PrivateLayout
        exact
        path="/email/info/:id"
        name="view email by id"
        component={EmailInfo}
      />
      <PrivateLayout
        exact
        path="/user/student/bulk/add"
        name="add-bulk-student-user"
        component={BulkStudentUserAdd}
      />
      <PrivateLayout
        exact
        path="/student/search"
        name="student search"
        component={StudentSearch}
      />
      <PrivateLayout
        exact
        path="/student/bulk/csv-upload"
        name="student search"
        component={BulkStudentUpload}
      />
      <PrivateLayout
        exact
        path="/student/show"
        name="student list show"
        component={StudentList}
      />
      <PrivateLayout
        exact
        path="/student/show/:id"
        name="student profile"
        component={StudentProfile}
      />
      <PrivateLayout
        exact
        path="/student/list"
        name="student list show"
        component={StudentListByGroupType}
      />
      <PrivateLayout
        exact
        path="/student/documents/:id"
        name="student documents"
        component={StudentDocuments}
      />
      <PrivateLayout
        exact
        path="/student/transfer-certificate/:id"
        name="student tc"
        component={StudentTCReport}
      />
      <PrivateLayout
        exact
        path="/student/performance/:id"
        name="student performance"
        component={StudentPerformance}
      />
      <PrivateLayout
        exact
        path="/student/emails/:id"
        name="student performance"
        component={StudentEmail}
      />
      <PrivateLayout
        exact
        path="/student/sms/:id"
        name="student sms"
        component={StudentSms}
      />
      <PrivateLayout
        exact
        path="/student/performance"
        name="student performance"
        component={StudentPerformance}
      />
      <PrivateLayout
        exact
        path="/fee/structure/:type"
        name="fee structure add"
        component={FeeStructure}
      />
      <PrivateLayout
        exact
        path="/fee/structure"
        name="fee structure list"
        component={FeeStructureList}
      />
      <PrivateLayout
        exact
        path="/fee/transport/structure/:type"
        name="add transport fee structure"
        component={TransportFeeStructure}
      />
      <PrivateLayout
        exact
        path="/fee/transport/list"
        name="transport fee structure list"
        component={TransportFeeStructureList}
      />
      <PrivateLayout
        exact
        path="/fee/assign/students"
        name="assign fees to students"
        component={AssignFees}
      />
      <PrivateLayout
        exact
        path="/fee/student/:id"
        name="assign fees to students"
        component={StudentFee}
      />
      <PrivateLayout
        exact
        path="/student/fee"
        name="assign fees to students"
        component={StudentFee}
      />
      <PrivateLayout
        exact
        path="/fee/student/show/receipt"
        name="assign fees to students"
        component={ViewReceipt}
      />
      <PrivateLayout
        exact
        path="/fee/report/students-feereceipt"
        name="fee receipt report"
        component={FeeCollection}
      />
      <PrivateLayout
        exact
        path="/fee/report/students"
        name="fee report per class"
        component={FeeRegister}
      />
      <PrivateLayout
        exact
        path="/fee/report/class-status"
        name="fee report per class"
        component={FeeStatusReport}
      />
      <PrivateLayout
        exact
        path="/fee/report/analytics"
        name="fee report per class"
        component={FeeAnalytics}
      />
      <PrivateLayout
        exact
        path="/fee/report/concession"
        name="fee report per class"
        component={FeeConcessionReport}
      />
      <PrivateLayout
        exact
        path="/fee/report/arrears"
        name="arrears fee report"
        component={FeeArrearReport}
      />
      <PrivateLayout
        exact
        path="/fee/report/waive"
        name="waive fee report"
        component={FeeWaiveReport}
      />
      <PrivateLayout
        exact
        path="/fee/report/bifurcation"
        name="bifurcation fee report"
        component={FeeGroupBifurcation}
      />
      <PrivateLayout
        exact
        path="/fee/report/student/details"
        name="bifurcation fee report"
        component={StudentFeeDetails}
      />
      <PrivateLayout
        exact
        path="/fee/report/deleted-fees"
        name="deleted fees list"
        component={DeletedFees}
      />
      <PrivateLayout
        exact
        path="/fee/delete-student-fee"
        name="arrears fee report"
        component={DeleteStudentFees}
      />
      <PrivateLayout
        exact
        path="/fee/bulk/fee-receipts"
        name="arrears fee report"
        component={BulkFeeReceipt}
      />
      <PrivateLayout
        exact
        path="/fee/concession/approval"
        name="arrears fee report"
        component={ConcessionFeeApproval}
      />
      <PrivateLayout
        exact
        path="/admissions/new"
        name="new admission"
        component={NewEnquiry}
      />
      <PrivateLayout
        exact
        path="/admissions/list"
        name="admission list"
        component={EnquiryList}
      />
      <PrivateLayout
        exact
        path="/admissions/list/new"
        name="admission list new"
        component={EnquiryListNew}
      />
      <PrivateLayout
        exact
        path="/admissions/application/:id"
        name="applicant profile"
        component={ApplicantProfile}
      />
      <PrivateLayout
        exact
        path="/attendance/entry/:type"
        name="applicant profile"
        component={AttendanceNew}
      />
      <PrivateLayout
        exact
        path="/attendance/new"
        name="attendance entry"
        component={AttendanceEntry}
      />
      <PrivateLayout
        exact
        path="/attendance/half-day"
        name="attendance entry"
        component={AttendanceHalfDay}
      />
      <PrivateLayout
        exact
        path="/attendance/bulk-entry"
        name="attendance entry"
        component={BulkEntry}
      />
      <PrivateLayout
        exact
        path="/attendance/attendance-delete"
        name="attendance delete"
        component={AttendanceDelete}
      />
      <PrivateLayout
        exact
        path="/attendance/daily/attendance-delete"
        name="daily attendance delete"
        component={DailyAttendanceDeletePerStudent}
      />
      <PrivateLayout
        exact
        path="/attendance-report"
        name="attendance report"
        component={AttendanceReport}
      />
      <PrivateLayout
        exact
        path="/attendance/attendance-report"
        name="attendance report"
        component={AttendanceReport}
      />
      <PrivateLayout
        exact
        path="/attendance/attendance-report/half-day"
        name="cpdc attendance report"
        component={CpdcAttendanceReport}
      />
      <PrivateLayout
        exact
        path="/attendance/attendance-report/teacher"
        name="teacher attendance report"
        component={TeacherAttendanceReport}
      />
      <PrivateLayout
        exact
        path="/attendance/report/subject-wise"
        name="attendance report subject wise"
        component={SubjectWiseAttendanceReport}
      />
      <PrivateLayout
        exact
        path="/studentgroup/topic-remarks"
        name="attendance remarks report"
        component={AttendanceRemarksReport}
      />
      <PrivateLayout
        exact
        path="/timetable/new"
        name="timetable"
        component={Timetable}
      />
      <PrivateLayout
        exact
        path="/student/generate/transfer-certificate"
        name="bulk generation of transfer certificate"
        component={TransferCertificate}
      />
      <PrivateLayout
        exact
        path="/student/generate/register-certificate"
        name="bulk generation of register certificate"
        component={RegisterCertificate}
      />
      <PrivateLayout
        exact
        path="/student/generate/conduct-certificate"
        name="bulk generation of conduct certificate"
        component={ConductCertificate}
      />
      <PrivateLayout
        exact
        path="/student/status/:type"
        name="bulk status of certificate"
        component={CertificateStatus}
      />
      <PrivateLayout
        exact
        path="/marks-card/generate"
        name="bulk generate of marks card"
        component={MarksCardGenerate}
      />
      <PrivateLayout
        exact
        path="/marks-card/status"
        name="bulk status of marks card"
        component={MarksCardStatus}
      />
      <PrivateLayout
        exact
        path="/marks/marks-card/generate"
        name="bulk generate of marks card"
        component={MarksCardGenerate}
      />
      <PrivateLayout
        exact
        path="/marks/marks-card/status"
        name="bulk status of marks card"
        component={MarksCardStatus}
      />
      <PrivateLayout
        exact
        path="/id-card/generate/:type"
        name="bulk generate of id card"
        component={IdCardGenerate}
      />
      <PrivateLayout
        exact
        path="/id-card/status/:type"
        name="bulk status of id card"
        component={IdCardStatus}
      />
      <PrivateLayout
        exact
        path="/events/add"
        name="add event"
        component={AddEvent}
      />
      <PrivateLayout
        exact
        path="/events/list"
        name="event list"
        component={EventList}
      />
      <PrivateLayout
        exact
        path="/rank/generate"
        name="generate rank"
        component={GenerateRank}
      />
      <PrivateLayout
        exact
        path="/marks/calculation"
        name="generate rank"
        component={GenerateRank}
      />
      <PrivateLayout
        exact
        path="/cms/list"
        name="cms list"
        component={CmsList}
      />
      <PrivateLayout exact path="/cms/add" name="add cms" component={AddCMS} />
      <PrivateLayout
        exact
        path="/cms/custom/:recepient"
        name="custom cms"
        component={CustomCms}
      />
      <PrivateLayout
        exact
        path="/report-card/publish"
        name="publish report card"
        component={PublishReportCard}
      />
      <PrivateLayout
        exact
        path="/report-cards"
        name="publish report card"
        component={StudentPublishedReportCard}
      />
      <PrivateLayout
        exact
        path="/gallery/upload"
        name="upload gallery"
        component={UploadGallery}
      />
      <PrivateLayout
        exact
        path="/gallery"
        name="gallery list"
        component={ViewGallery}
      />
      <PrivateLayout
        exact
        path="/gallery/album/:id"
        name="view album"
        component={ViewAlbum}
      />
      <PrivateLayout
        exact
        path="/student-list/boarders"
        name="boarders list"
        component={BoardersList}
      />
      <PrivateLayout
        exact
        path="/student-list/subjects"
        name="student subject list"
        component={StudentSubjectlist}
      />
      <PrivateLayout
        exact
        path="/student-list/count"
        name="student count"
        component={StudentCount}
      />
      <PrivateLayout
        exact
        path="/teacher/show"
        name="teacher list"
        component={TeacherList}
      />
      <PrivateLayout
        exact
        path="/teacher/show/:id"
        name="teacher profile"
        component={TeacherProfile}
      />
      <PrivateLayout
        exact
        path="/teacher/documents/:id"
        name="teacher documents"
        component={TeacherDocuments}
      />
      <PrivateLayout
        exact
        path="/teacher/edit/:id"
        name="edit teacher"
        component={AddEditTeacher}
      />
      <PrivateLayout
        exact
        path="/teacher/add"
        name="add teacher"
        component={AddEditTeacher}
      />
      <PrivateLayout
        exact
        path="/reset-password"
        name="reset password"
        component={ForgotPasswordComponent}
      />
      <PrivateLayout
        exact
        path="/attendance/hourly/status"
        name="reset password"
        component={HourlyCompletionReport}
      />
      <PrivateLayout
        exact
        path="/attendance/hourly/weekly-report"
        name="attendance weekly report"
        component={WeeklyHourlyAttendanceReport}
      />
      <PrivateLayout
        exact
        path="/attendance/hourly/correction-new"
        name="attendance hourly correction"
        component={AttendanceCorrection}
      />
      <PrivateLayout
        exact
        path="/email/bulk"
        name="bulk email"
        component={BulkEmail}
      />
      <PrivateLayout
        exact
        path="/email/custom/:recepient"
        name="custom email"
        component={CustomEmail}
      />
      <PrivateLayout
        exact
        path="/fee/tally-consolidated"
        name="consolidated fee summary"
        component={ConsolidatedFeeReport}
      />
      <PrivateLayout
        exact
        path="/fee/deferred-income"
        name="consolidated fee summary"
        component={DeferredFee}
      />
      <PrivateLayout
        exact
        path="/fee/deleted-receipts"
        name="consolidated fee summary"
        component={DeletedReceipts}
      />
      <PrivateLayout
        exact
        path="/marks/test-analysis"
        name="test wise analysis"
        component={TestWiseAnalysis}
      />
      <PrivateLayout
        exact
        path="/marks/graphical-analysis"
        name="graphical analysis"
        component={GraphicalAnalysis}
      />
      {/* <PrivateLayout
        exact
        path="/marks/table-analysis"
        name="graphical analysis"
        component={TabularAnalysis}
      /> */}
      <PrivateLayout
        exact
        path="/marks/tabular-analysis/new"
        name="graphical analysis"
        component={TabularAnalysisNew}
      />
      <PrivateLayout
        exact
        path="/marks/custom-report"
        name="graphical analysis"
        component={RBANMSConsolidated}
      />
      <PrivateLayout
        exact
        path="/marks/remarks/:type"
        name="marks remarks"
        component={StudentMarksRemarks}
      />
      <PrivateLayout
        exact
        path="/student-remarks/add"
        name="marks remarks"
        component={AddStudentRemarks}
      />
      <PrivateLayout
        exact
        path="/student-remarks/list"
        name="marks remarks"
        component={ListRemarks}
      />
      <PrivateLayout
        exact
        path="/teacher/csv-upload"
        name="marks remarks"
        component={BulkTeacherUpload}
      />
      <PrivateLayout
        exact
        path="/promotion/duplicate-class"
        name="duplicate class for next year"
        component={DuplicateClassForNextYear}
      />
      <PrivateLayout
        exact
        path="/promotion/duplicate-test"
        name="duplicate test"
        component={DuplicateTest}
      />
      <PrivateLayout
        exact
        path="/promotion/students"
        name="duplicate test"
        component={PromoteStudents}
      />
      <PrivateLayout
        exact
        path="/sibling/login/create"
        name="create sibling login"
        component={CreateSiblingLogin}
      />
      <PrivateLayout
        exact
        path="/student/document/request"
        name="request student document"
        component={StudentDocumentAlert}
      />
      <PrivateLayout
        exact
        path="/student/document/request/list"
        name="list of student document request"
        component={StudentDocumentAlertList}
      />
      <PrivateLayout
        exact
        path="/student/documents/upload/:id"
        name="upload requested student document"
        component={StudentUploadDocument}
      />
      <PrivateLayout
        exact
        path="/student/document-request"
        name="list of student document request"
        component={DocumentListByType}
      />
      <PrivateLayout
        exact
        path="/profile/student/upload/me"
        name="upload requested student document"
        component={StudentUploadDocument}
      />
      <PrivateLayout
        exact
        path="/feeTypes"
        name="institution fee types"
        component={FeeTypeList}
      />
      <PrivateLayout
        exact
        path="/challan"
        name="challan list"
        component={ChallanList}
      />
      <PrivateLayout
        exact
        path="/fee/fine-template/list"
        name="fine template list"
        component={ListFineTemplate}
      />
      {/* lesson plan */}
      <PrivateLayout
        exact
        path="/lessonPlan/add"
        name="Lesson Plan edit"
        component={LessonPlanAddEdit}
      />
      <PrivateLayout
        exact
        path="/lessonPlan/edit/:id"
        name="Lesson Plan add"
        component={LessonPlanAddEdit}
      />
      <PrivateLayout
        exact
        path="/me/lessonPlan/list"
        name="My Lesson Plans"
        component={LessonPlanList}
      />
      <PrivateLayout
        exact
        path="/lessonPlan/list"
        name="My Lesson Plans"
        component={LessonPlanList}
      />
      <PrivateLayout
        exact
        path="/lessonPlan/list/:teacherId"
        name="My Lesson Plans"
        component={LessonPlanList}
      />
      <PrivateLayout
        exact
        path="/lesson-plan/verification"
        name="Lesson Plan list for verification"
        component={LessonPlanVerification}
      />
      <PrivateLayout
        exact
        path="/lesson-plan/approval"
        name="Lesson Plan list for approval"
        component={LessonPlanApproval}
      />
      {/* student teacher certificate*/}
      <PrivateLayout
        exact
        path="/certificate/add"
        name="student teacher Certificate add"
        component={StudentCertificateAdd}
      />
      <PrivateLayout
        exact
        path="/certificate/list"
        name="student teacher Certificate list"
        component={StudentCertificateList}
      />
      <PrivateLayout
        exact
        path="/notes/add"
        name="add notes"
        component={AddNotes}
      />
      <PrivateLayout
        exact
        path="/notes/list"
        name="list notes"
        component={ListNotes}
      />
      <PrivateLayout
        exact
        path="/student/archived/show"
        name="archived students"
        component={ArchivedStudents}
      />
      <PrivateLayout
        exact
        path="/sibling/parent/list"
        name="parent list"
        component={ParentList}
      />
      <PrivateLayout
        exact
        path="/mom/add"
        name="add mom"
        component={MomentsOfMeeting}
      />
      <PrivateLayout
        exact
        path="/mom/view/:momId"
        name="add mom"
        component={MomentsOfMeetingView}
      />
      <PrivateLayout
        exact
        path="/mom/list"
        name="add mom"
        component={MomentsOfMeetingList}
      />

      {/* transportation */}
      <PrivateLayout
        exact
        path="/transport/vehicle/add"
        name="add transport vehicle"
        component={AddVehicle}
      />
      <PrivateLayout
        exact
        path="/transport/vehicle/list"
        name="list transport vehicle"
        component={ListVehicle}
      />
      <PrivateLayout
        exact
        path="/transport/driver/add"
        name="add driver"
        component={AddEditDriver}
      />
      <PrivateLayout
        exact
        path="/transport/driver/:id/edit"
        name="add driver"
        component={AddEditDriver}
      />
      <PrivateLayout
        exact
        path="/transport/driver/list"
        name="list driver"
        component={ListDriver}
      />
      <PrivateLayout
        exact
        path="/transport/route/add"
        name="add route"
        component={AddRoute}
      />
      <PrivateLayout
        exact
        path="/transport/route/list"
        name="list route"
        component={ListRoute}
      />
      <PrivateLayout
        exact
        path="/transport/route/:id/stop"
        name="list stops"
        component={StopList}
      />
      <PrivateLayout
        exact
        path="/transport/route/:id/stop/add"
        name="add stop"
        component={AddEditStop}
      />
      <PrivateLayout
        exact
        path="/transport/route/:id/stop/assign"
        name="assign members"
        component={AssignMembers}
      />
      <PrivateLayout
        exact
        path="/transport/route/:id/stop/unassign"
        name="assign members"
        component={UnassignMembers}
      />

      {/* <Route component={NotFound} /> */}

      {/* MIT Reports - PO-CO Attainment */}
      <PrivateLayout
        exact
        path="/report/co-attainment"
        name="CO Attainment report"
        component={COAttainment}
      />
      <PrivateLayout
        exact
        path="/ticket/list"
        name="List of tickets"
        component={QueryListTable}
      />
      <PrivateLayout
        exact
        path="/ticket/view/:ticketId"
        name="ticket chats"
        component={QueryChat}
      />
      <PrivateLayout
        exact
        path="/lesson-plan/chapter/add"
        name="add lesson plan chapters"
        component={AddChapters}
      />
      <PrivateLayout
        exact
        path="/lesson-plan/chapter/list"
        name="list lesson plan chapters"
        component={ListChapters}
      />
      <PrivateLayout
        exact
        path="/ticket/report"
        name="List of tickets"
        component={queryTicketReport}
      />
      <PrivateLayout
        exact
        path="/student/upload-picture"
        name="List of tickets"
        component={StudentPictureUpload}
      />
      <PrivateLayout
        exact
        path="/institution/add-device"
        name="Add Biometric Device"
        component={AddBiometricDevice}
      />
      <PrivateLayout
        exact
        path="/teacher/assign-fingerprint"
        name="Assign Fingerprint"
        component={AssignTeacherToFingerPrint}
      />
      <PrivateLayout
        exact
        path="/marks/report/periodic-test"
        name="Periodic Test Report"
        component={PeriodicTestReport}
      />
      <PrivateLayout
        exact
        path="/teacher/certificates/:id"
        name="Teacher certificates"
        component={TeacherCertificates}
      />
      <PrivateLayout
        exact
        path="/library/summary"
        name="List of book lendings"
        component={LibrarySummary}
      />
      <PrivateLayout
        exact
        path="/library/bulk/catalog"
        name="Catalog bulk upload csv"
        component={BulkCatalogUpload}
      />
      <PrivateLayout
        exact
        path="/attendance/hourly/subject-wise/report"
        name="Catalog bulk upload csv"
        component={VijayaAttendanceReportSubjectwise}
      />
    </>
  )
}

export default CommonRoute
