import {
  APP_LOGOUT,
  POST_LIBRARY_CATALOG_START,
  POST_LIBRARY_CATALOG,
  POST_LIBRARY_CATALOG_FAIL,
  GET_LIBRARY_CATALOG_POLICIES_START,
  GET_LIBRARY_CATALOG_POLICIES,
  GET_LIBRARY_CATALOG_POLICIES_FAIL,
  GET_LIBRARY_CATALOG_STATS_START,
  GET_LIBRARY_CATALOG_STATS,
  GET_LIBRARY_CATALOG_STATS_FAIL,
  GET_LIBRARY_CATALOG_TAGS_START,
  GET_LIBRARY_CATALOG_TAGS,
  GET_LIBRARY_CATALOG_TAGS_FAIL,
  DELETE_LIBRARY_CATALOG_START,
  DELETE_LIBRARY_CATALOG,
  DELETE_LIBRARY_CATALOG_FAIL,
  SAVE_SELECTED_LIBRARY_CATALOGS,
  SAVE_LIBRARY_CATALOG_EDIT_STATUS,
  CLEAR_LIBRARY_CATALOG_EDIT_STATUS,
  SAVE_SELECTED_LIBRARY_CATALOG,
  PATCH_LIBRARY_CATALOG_POLICY_START,
  PATCH_LIBRARY_CATALOG_POLICY_SUCCESS,
  PATCH_LIBRARY_CATALOG_POLICY_FAIL,
  DELETE_LIBRARY_CATALOG_POLICY_START,
  DELETE_LIBRARY_CATALOG_POLICY,
  DELETE_LIBRARY_CATALOG_POLICY_FAIL,
  UPDATE_CATALOG_BULK_UPLOAD_START,
  UPDATE_CATALOG_BULK_UPLOAD_SUCCESS,
  UPDATE_CATALOG_BULK_UPLOAD_FAIL
} from '../../helpers/actions'

const initialState = {
  catalog: '',
  isLoadingPostLibraryCatlog: false,
  catalogPolicies: [],
  isLoadingGetLibraryCatlogPolicies: false,
  isLoadingDeleteLibraryCatlog: false,
  catalogPoliciesById: {},
  catalogById: {},
  selectedCatalogs: [],
  error: false,
  editStatus: false,
  selctedEditCatalog: {},
  isLoadingGetLibraryCatlogStats: false,
  catalogStats: [],
  isLoadingGetLibraryCatlogTags: false,
  catalogTags: [],
  isPatchPolicyLoading: false,
  isCatalogBulkUploadLoading: false
}

export const libraryCatlog = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_LIBRARY_CATALOG_START:
      return Object.assign({}, state, {
        isLoadingDeleteLibraryCatlog: true,
        error: false
      })
    case DELETE_LIBRARY_CATALOG:
      return Object.assign({}, state, {
        isLoadingDeleteLibraryCatlog: false,
        error: false
      })
    case DELETE_LIBRARY_CATALOG_FAIL:
      return Object.assign({}, state, {
        isLoadingDeleteLibraryCatlog: false,
        error: true
      })
    case POST_LIBRARY_CATALOG_START:
      return Object.assign({}, state, {
        isLoadingPostLibraryCatlog: true,
        error: false
      })
    case POST_LIBRARY_CATALOG:
      return Object.assign({}, state, {
        isLoadingPostLibraryCatlog: false,
        catalog: action.data,
        catalogById: Object.assign({}, state.catalogById, action.byId),
        error: false
      })
    case POST_LIBRARY_CATALOG_FAIL:
      return Object.assign({}, state, {
        isLoadingPostLibraryCatlog: false,
        error: true
      })
    case GET_LIBRARY_CATALOG_POLICIES_START:
      return Object.assign({}, state, {
        isLoadingGetLibraryCatlogPolicies: true,
        error: false
      })
    case GET_LIBRARY_CATALOG_POLICIES:
      return Object.assign({}, state, {
        isLoadingGetLibraryCatlogPolicies: false,
        catalogPolicies: action.data,
        catalogPoliciesById: action.byId,
        error: false
      })
    case GET_LIBRARY_CATALOG_POLICIES_FAIL:
      return Object.assign({}, state, {
        isLoadingGetLibraryCatlogPolicies: false,
        error: true
      })
    case GET_LIBRARY_CATALOG_STATS_START:
      return Object.assign({}, state, {
        isLoadingGetLibraryCatlogStats: true,
        error: false
      })
    case GET_LIBRARY_CATALOG_STATS:
      return Object.assign({}, state, {
        isLoadingGetLibraryCatlogStats: false,
        catalogStats: action.data,
        error: false
      })
    case GET_LIBRARY_CATALOG_STATS_FAIL:
      return Object.assign({}, state, {
        isLoadingGetLibraryCatlogStats: false,
        error: true
      })
    case GET_LIBRARY_CATALOG_TAGS_START:
      return Object.assign({}, state, {
        isLoadingGetLibraryCatlogTags: true,
        error: false
      })
    case GET_LIBRARY_CATALOG_TAGS:
      return Object.assign({}, state, {
        isLoadingGetLibraryCatlogTags: false,
        catalogTags: action.data,
        error: false
      })
    case GET_LIBRARY_CATALOG_TAGS_FAIL:
      return Object.assign({}, state, {
        isLoadingGetLibraryCatlogTags: false,
        error: true
      })
    case SAVE_SELECTED_LIBRARY_CATALOGS:
      return Object.assign({}, state, {
        selectedCatalogs: action.data
      })
    case SAVE_LIBRARY_CATALOG_EDIT_STATUS:
      return Object.assign({}, state, {
        editStatus: true
      })
    case CLEAR_LIBRARY_CATALOG_EDIT_STATUS:
      return Object.assign({}, state, {
        editStatus: false
      })
    case SAVE_SELECTED_LIBRARY_CATALOG:
      return Object.assign({}, state, {
        selctedCatalog: action.data
      })
    case PATCH_LIBRARY_CATALOG_POLICY_START:
      return Object.assign({}, state, {
        isPatchPolicyLoading: true,
        error: false
      })
    case PATCH_LIBRARY_CATALOG_POLICY_SUCCESS:
      return Object.assign({}, state, {
        isPatchPolicyLoading: false,
        error: false
      })
    case PATCH_LIBRARY_CATALOG_POLICY_FAIL:
      return Object.assign({}, state, {
        isPatchPolicyLoading: false,
        error: true
      })
    case DELETE_LIBRARY_CATALOG_POLICY_START:
      return Object.assign({}, state, {
        isPatchPolicyLoading: true,
        error: false
      })
    case DELETE_LIBRARY_CATALOG_POLICY:
      return Object.assign({}, state, {
        isPatchPolicyLoading: false,
        error: false
      })
    case DELETE_LIBRARY_CATALOG_POLICY_FAIL:
      return Object.assign({}, state, {
        isPatchPolicyLoading: false,
        error: true
      })
    case UPDATE_CATALOG_BULK_UPLOAD_START:
      return Object.assign({}, state, {
        isCatalogBulkUploadLoading: true,
        error: false
      })
    case UPDATE_CATALOG_BULK_UPLOAD_SUCCESS:
      return Object.assign({}, state, {
        isCatalogBulkUploadLoading: false,
        error: false
      })
    case UPDATE_CATALOG_BULK_UPLOAD_FAIL:
      return Object.assign({}, state, {
        isCatalogBulkUploadLoading: false,
        error: true
      })
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

// selectors
export const getError = state =>
  state.libraryCatlog.error ? state.libraryCatlog.error : false
export const getLibraryCatalog = state =>
  state.libraryCatlog.catalog ? state.libraryCatlog.catalog : {}
export const getisLoadingPostLibraryCatlog = state =>
  state.libraryCatlog.isLoadingPostLibraryCatlog
    ? state.libraryCatlog.isLoadingPostLibraryCatlog
    : false
export const getisLoadingDeleteLibraryCatlog = state =>
  state.libraryCatlog.isLoadingDeleteLibraryCatlog
    ? state.libraryCatlog.isLoadingDeleteLibraryCatlog
    : false
export const getLibraryCatalogPolicies = state =>
  state.libraryCatlog.catalogPolicies ? state.libraryCatlog.catalogPolicies : {}
export const getisLoadingGetLibraryCatlogPolicies = state =>
  state.libraryCatlog.isLoadingGetLibraryCatlogPolicies
    ? state.libraryCatlog.isLoadingGetLibraryCatlogPolicies
    : false
export const getcatalogPoliciesById = state =>
  state.libraryCatlog.catalogPoliciesById
    ? state.libraryCatlog.catalogPoliciesById
    : {}
export const getCatalogById = state =>
  state.libraryCatlog.catalogById ? state.libraryCatlog.catalogById : {}

export const getEditStatus = state => state.libraryCatlog.editStatus
export const getSelctedCatalog = state => state.libraryCatlog.selctedCatalog

export const getSelectedCatalogs = state =>
  state.libraryCatlog.selectedCatalogs
    ? state.libraryCatlog.selectedCatalogs
    : {}

export const getisLoadingGetLibraryCatlogStats = state =>
  state.libraryCatlog.isLoadingGetLibraryCatlogStats
    ? state.libraryCatlog.isLoadingGetLibraryCatlogStats
    : false
export const getCatalogStats = state =>
  state.libraryCatlog.catalogStats ? state.libraryCatlog.catalogStats : []
export const getisLoadingGetLibraryCatlogTags = state =>
  state.libraryCatlog.isLoadingGetLibraryCatlogTags
    ? state.libraryCatlog.isLoadingGetLibraryCatlogTags
    : false
export const getCatalogTags = state =>
  state.libraryCatlog.catalogTags ? state.libraryCatlog.catalogTags : []
export const getIsPatchCatalgPolicyLoading = state =>
  state.libraryCatlog.isPatchPolicyLoading
    ? state.libraryCatlog.isPatchPolicyLoading
    : false

export const getBulkCatalogUploadLoading = state =>
  state.libraryCatlog.isCatalogBulkUploadLoading
