import {
  APP_LOGOUT,
  POST_CHALLAN_GENERATE_START,
  POST_CHALLAN_GENERATE_SUCCESS,
  POST_CHALLAN_GENERATE_FAIL,
  GET_CHALLAN_START,
  GET_CHALLAN_SUCCESS,
  GET_CHALLAN_FAIL
} from '../helpers/actions'

const initialState = {
  isLoading: false,
  isErr: false,
  challanData: [],
  studentChallanData: []
}

export const challan = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case POST_CHALLAN_GENERATE_START:
      return {
        ...state,
        isLoading: true,
        isErr: false,
        studentChallanData: []
      }
    case POST_CHALLAN_GENERATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isErr: false,
        studentChallanData: Array.isArray(action.data)
          ? action.data
          : [action.data]
      }
    case POST_CHALLAN_GENERATE_FAIL:
      return {
        ...state,
        isLoading: false,
        isErr: true,
        studentChallanData: []
      }
    case GET_CHALLAN_START:
      return {
        ...state,
        isLoading: true,
        isErr: false,
        challanData: []
      }
    case GET_CHALLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isErr: false,
        challanData: action.data
      }
    case GET_CHALLAN_FAIL:
      return {
        ...state,
        isLoading: false,
        isErr: true,
        challanData: []
      }
    default:
      return state
  }
}

export const getIsLoading = state => state.challan.isLoading
export const getError = state => state.challan.isErr
export const getChallanData = state => state.challan.challanData
export const getStudentChallanData = state => state.challan.studentChallanData
